import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Daniel Rasmussen', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Daniel Rasmussen a.k.a drGeo', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hejsan, mitt namn är',
  name: 'Daniel Rasmussen',
  subtitle: 'jag är också känd som drGeo',
  cta: 'Visa mer',
};

// ABOUT DATA
export const aboutData = {
  img: 'drgeo.jpg',
  paragraphOne:
    'Positiv och engagerad medarbetare. Tar ansvar för leveranstider, bidrar med idéer, ser på problem från andra perspektiv och trivs med att samarbeta. Söker just nu en miljö där det jobbas i team med systemutveckling och geodata. Svåra uppgifter stimulerar och det är något som driver engagemanget. Det som väger tyngst är stimulerande arbetsuppgifter.',
  paragraphTwo: ' ',
  paragraphThree: ' ',
  resume: 'https://www.cv-mallen.se/online-cv/52qcnryqhfjp/ogqzruha', // if no resume, the button will not show up
  pb: 'https://www.cv-mallen.se/personligt-brev/52qcnryqhfjp/ogqzruha',
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'origo.png',
    title: 'Origo',
    info: 'An easy to configure framework for creating your own web mapping applications.',
    info2: '',
    url: '',
    repo: 'https://github.com/origo-map/origo', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'duria.png',
    title: 'Duria',
    info: 'Examensarbete, crowdsouring.',
    info2: '',
    url: 'http://hig.diva-portal.org/smash/record.jsf?pid=diva2%3A1182238&dswid=9148',
    repo: 'https://github.com/daRasmussen/duria', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'skj.png',
    title: 'Skjutjärnsgatan',
    info: 'Västerås power trio.',
    info2: '',
    url: 'http://www.skjutjarnsgatan.se',
    repo: 'https://github.com/skjutjarnsgatan', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Är du min nästa arbetskollega? Härligt!',
  btn: 'Låt oss snacka!',
  email: 'daniel.rasmussen@drgeo.se',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://twitter.com/dr865941',
    },
    {
      id: nanoid(),
      name: 'codepen',
      url: 'https://codepen.io/darasmussen',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://se.linkedin.com/in/daniel-rasmussen-ab8395a0',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/daRasmussen',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
